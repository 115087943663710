<template>
  <ValidationObserver ref="formValidation">
    <form @submit.prevent method="POST">

      <div class="form-group">
        <validation-provider
          #default="{ errors }"
          name="Correo electronico"
          rules="required|email"
        >
          <label>Correo electronico</label>
          <b-form-input
            placeholder="Correo electronico"
            v-model="email.to"
          ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="form-group">
        <validation-provider
          #default="{ errors }"
          name="Asunto"
          rules="required"
        >
          <label>Asunto</label>
          <b-form-input
            placeholder="Asunto del correo"
            v-model="email.subject"
          ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="form-group">
        <validation-provider
          #default="{ errors }"
          name="Contenido"
          rules="required"
        >
          <label>Contenido</label>
          <b-form-textarea
            rows="6"
            placeholder="Contenido del correo"
            v-model="email.content"
          ></b-form-textarea>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>
      <div class="text-center" style="padding-top:20px">
        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            :disabled="loading"
            variant="primary"
            button="submit"
            @click="sendEmail()"
          >
            <i class="fa fa-send"></i>
            Enviar
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import UtilitiesService from "@/services/utilities.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormInput, BFormTextarea, BButton, BOverlay } from "bootstrap-vue";
export default {
  props: {
    document_type: {
      default: null
    },
    document: {
      default: null
    },
    email: {
      type: Object,
      default: () => ({
        subject: "",
        content: "",
        to: ''
      })
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BButton,
    BOverlay,
    BFormTextarea
  },
  data() {
    return {

      subjectsAndContents: null,
      loading: false
    };
  },
  methods: {
    getSubjectsAndContentsEmail() {
      UtilitiesService.getSubjectsAndContentsEmail()
        .then(res => {
          this.subjectsAndContents = res.data;
        })
        .catch(error => {
          alert(error.message);
        });
    },
    sendEmail() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.loading = true;

          UtilitiesService.sendDocumentEmail({
            subject_email: this.email.subject,
            content_email: this.email.content,
            document_id: this.document.id,
            document_type: this.document_type,
            email: this.email.to
          })
            .then(res => {

              this.loading = false;

              this.$emit("sendEmail", res.data);
            })
            .catch(error => {
              this.loading = false;

              alert("Ocurrio un problema al enviar el correo");
            });
        }
      });
    }
  },
  watch: {
    document(newVal) {
      if (newVal) {
        if (this.document_type === "fe") {
          this.email.subject = this.subjectsAndContents.invoice.subject;
          this.email.content = this.subjectsAndContents.invoice.content;
        } else if (this.document_type === "nc" || this.document_type === "nd") {
          this.email.subject = this.subjectsAndContents.note.subject;
          this.email.content = this.subjectsAndContents.note.content;

          if (this.document_type === "nc") {
            this.email.subject = this.email.subject.replace(
              "{note_type}",
              "credito"
            );
            this.email.content = this.email.content.replace(
              "{note_type}",
              "credito"
            );
          } else {
            this.email.subject = this.email.subject.replace(
              "{note_type}",
              "debito"
            );
            this.email.content = this.email.content.replace(
              "{note_type}",
              "credito"
            );
          }
        }

        this.email.content = this.email.content.replace(
          "{client_name}",
          this.document.client.full_name
        );

        this.email.content = this.email.content.replace(
          "{reference}",
          this.document.reference
        );

        this.email.subject = this.email.subject.replace(
          "{reference}",
          this.document.reference
        );
      }
    }
  },
  mounted() {
    this.getSubjectsAndContentsEmail();
  }
};
</script>
