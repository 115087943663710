<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <div class="p-1 cursor-pointer">
      <div class="chat-info flex-grow-1 w-100">
        <div class="d-flex justify-content-between mb-1">
          <h4 class="mb-0 text-secondary">
            {{ invoice.client.full_name }}
          </h4>
          <p class="mb-25">
            {{ currencyFormat(invoice.total) }}
          </p>
        </div>

        <p class="card-text text-truncate">
          <b class="text-primary"># {{ invoice.reference }}</b> | {{ formatDate(invoice.created_at) }}
        </p>
      </div>
    </div>
  </component>
</template>

<script>
import moment from 'moment'
moment.locale('es')

import { currencyFormat } from '@/helpers'

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    invoice: {
      type: Object,
      required: true,
    }
  },
  methods: {
    formatDate(date, format = 'DD/MM/YYYY') {
      return moment(date).format(format)
    },
    currencyFormat(amount) {
      return currencyFormat(amount)
    }
  }
}
</script>
